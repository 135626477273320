<template>
    <div class="con-wrap">
        <CarrotTitle title="나의 교육관리"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-col" >
                        <colgroup>
                            <col span="4">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>필요 교육 시간</th>
                                <th>이수 교육 수</th>
                                <th>교육 이수 시간</th>
                                <th>교육시간 이수율</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ info.required_time }}시간</td>
                                <td>{{ info.complete_cnt  }}</td>
                                <td>{{ info.complete_time }}시간</td>
                                <td>{{ info.complete_rate }}%</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-50 mb-20">
                        <table class="table-row table-serach">
                            <tbody>
                                <tr>
                                    <th width="180">교육 명</th>
                                    <td>
                                        <input type="text" class="w-100per" v-model="bbs.title">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button class="btn-default float-right mt-10" @click="bbs.doSearch">검색</button>
                        <div class="clear"></div>
                    </div>
                    
                    <div class="mt-50 mb-20">
                        <select name="syear" id="syear" class="w-100px float-left" v-model="bbs.syear" @change="bbs.doSearch">
                            <option :value="v" v-for="(v, k) in bbs.search_years" :key="k">{{ v }}</option>
                        </select>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="300">
                            <col width="*">
                            <col width="150">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>교육기간</th>
                                <th>교육 명</th>
                                <th>이수시간</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.sdate }}{{ irow.edate?'~'+irow.edate:'' }}</td>
                                <td>{{ irow.title }}</td>
                                <td>{{ irow.complete_time }}</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="4">이수한 교육이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import axios from '@/plugins/axios.js'


export default {
    layout:"myPIMS",
    components: {
    },
    setup() {
        const router = useRouter()

        const info = reactive({
            required_time : 0,
            complete_cnt  : 0,
            complete_time : 0,
            complete_rate : 0,

            getInfo: () => {
                let params = { };
                axios.get('/rest/mypims/myEducationInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        info.required_time = res.data.required_time;
                        info.complete_cnt  = res.data.complete_cnt;
                        info.complete_time = res.data.complete_time;
                        info.complete_rate = res.data.complete_rate;
                        info.joindate      = res.data.joinday;

                        bbs.makeSearchYears();
                    } else {
                        console.info(res.data.err_msg);
                    }
                });
            }
        });

        const bbs = reactive({
            search_years : [ ],
            page : 1,
            rows : 50,

            syear: "",
            title: "",
            list : [], total : 0,

            doSearch : () => {
                bbs.page = 1;
                bbs.getList();
            },
            getList: () => {
                let params = {
                    page   : bbs.page,
                    rows   : bbs.rows,
                    tgdate : bbs.syear,
                    title  : bbs.title
                };
                axios.get('/rest/mypims/educationList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        console.info(res.data.err_msg);
                    }
                })
            },
            showView: (idx)=>{
                // location.href = "myEduManagementView/" + idx;
                router.push({
                    name   : 'CARROTZone-myEduManagementView-idx',
                    params : { idx:idx }
                });
            },
            showAdd: () => {
                // location.href = "myEduManagementAdd";
                router.push('myEduManagementAdd');
            },
            makeSearchYears: () => {
                let cd = new Date();
                let cy = cd.getFullYear();
                let jd = new Date(info.joindate);

                for(let i=jd.getFullYear(); i<cy+1; i++){
                    bbs.search_years.push(i);
                }
            }
        });

        
        onMounted(() => {
            // Mounted
            let td = new Date();
            bbs.syear = td.getFullYear();

            info.getInfo();
            bbs.getList();
        });

        onUnmounted(() => { 
            // UnMounted
        });
        return {bbs, info};
    }
}
</script>

<style lang="scss" scoped>
</style>